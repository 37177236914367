import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs/internal/Subscription';
import { User } from 'src/app/classes/user';
import { UserService } from 'src/app/services/user.service';
import { CustomValidators } from 'src/app/validators/validators';

@Component({
  selector: 'app-primeiroacesso',
  templateUrl: './primeiroacesso.component.html',
  styleUrls: ['./primeiroacesso.component.scss']
})
export class PrimeiroacessoComponent implements OnInit, OnDestroy {

  data: { user: User };
  userForm: FormGroup;
  dialogRef: any;
  user: User
  visibility: ''

  constructor(private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private userService: UserService,
              private storage: Storage) { }

  protected subscriptions: Subscription[] = [];

  ngOnInit() {
    this.user = this.data.user;
    this.userForm = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.maxLength(255),
        Validators.minLength(8),
      ])],
      password_confirmation: [''],
    }, {
      validator: CustomValidators.passwordConfirmation
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  get password(): AbstractControl {
    return this.userForm.get('password');
  }

  get password_confirmation(): AbstractControl {
    return this.userForm.get('password_confirmation');
  }


  save(): void {

    this.userForm.markAllAsTouched();

    if (this.userForm.valid) {
      this.user.password = this.password.value
      this.subscriptions.push(this.userService.primeiroAcesso(this.user).subscribe(response => {
        if (response.succcess) {
          this.snackBar.open(response.message, 'Fechar', {duration: 5000});
          this.dialogRef.close(true);
        } else {
          this.snackBar.open(response.message, 'Fechar', {duration: 5000});
          this.dialogRef.close(true);
        }
      }))
    }

    }

  }

