import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClientService} from '../../services/client.service';
import {Client} from '../../classes/client';
import {MatPaginator} from '@angular/material/paginator';
import {Subscription} from 'rxjs';
import {MediaObserver} from '@angular/flex-layout';
import {MdcDialog} from '@angular-mdc/web';
import {ClientShowComponent} from './client-show/client-show.component';
import {NewButtonClickService} from '../../services/new-button-click.service';
import {ClientCreateComponent} from './client-create/client-create.component';
import {MatSort} from '@angular/material/sort';
import {SearchService} from '../../services/search.service';
import {DialogComponent} from '../../shared/dialog/dialog.component';
import {PermissionService} from '../../services/permission.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit, OnDestroy, AfterViewInit {
  clients: Client[] = [];
  columns: string[] = [];

  protected subscriptions: Subscription[] = [];

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(private clientService: ClientService,
              private permissionService: PermissionService,
              private newButtonClickService: NewButtonClickService,
              private searchService: SearchService,
              private snackBar: MatSnackBar,
              private dialog: MdcDialog,
              public mediaObserver: MediaObserver) {
  }

  ngOnInit(): void {
    this.subscriptions.push(this.mediaObserver.asObservable().subscribe(() => this.responsiveColumns()));
    this.subscriptions.push(this.newButtonClickService.observable().subscribe(() => this.create()));
    this.permissionService.hasPermission('clients:create')
      .then((checked: boolean) => this.newButtonClickService.toggleDisabled(!checked));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.index();
  }

  index() {
    this.subscriptions.push(this.searchService.index(this.sort, this.paginator, this.clientService)
      .subscribe((clients: Client[]) => this.clients = clients));
  }

  create() {
    this.subscriptions.push(this.dialog.open(DialogComponent, {
      scrollable: true,
      escapeToClose: true,
      clickOutsideToClose: true,
      autoFocus: false,
      data: {
        component: ClientCreateComponent,
        title: 'Criar nova aplicação',
        footerActions: true
      }
    }).afterClosed().subscribe((success: boolean) => {
      if (success && typeof success === 'boolean') {
        this.index();
      }
    }));
  }

  show(client: Client) {
    this.permissionService.hasPermission('clients:show', true)
      .then((checked: boolean) => {
        if (checked) {
          this.subscriptions.push(this.dialog.open(DialogComponent, {
            scrollable: true,
            escapeToClose: true,
            clickOutsideToClose: true,
            autoFocus: false,
            data: {
              component: ClientShowComponent,
              title: client.name,
              headerActions: true,
              info: {client},
              editPermission: 'clients:edit',
              destroyPermission: 'clients:destroy',
            }
          }).afterClosed().subscribe((success: boolean) => {
            if (success && typeof success === 'boolean') {
              this.index();
            }
          }));
        }
      });
  }

  private responsiveColumns() {
    this.columns = ['name'];

    if (this.mediaObserver.isActive('gt-md')) {
      this.columns.push('secret');
    }

    if (this.mediaObserver.isActive('gt-lg')) {
      this.columns.push('redirect');
      this.columns.push('updated_at');
    }
  }
}
