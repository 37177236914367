import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientsRoutingModule} from './clients-routing.module';
import {ClientsComponent} from './clients.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import {
  MdcCardModule,
  MdcDialogModule,
  MdcIconButtonModule,
  MdcIconModule,
  MdcListModule,
  MdcTextFieldModule,
  MdcTypographyModule
} from '@angular-mdc/web';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ClientShowComponent} from './client-show/client-show.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ClientCreateComponent} from './client-create/client-create.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ClientEditComponent} from './client-edit/client-edit.component';
import {SharedModule} from '../../shared/shared.module';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    ClientsComponent,
    ClientShowComponent,
    ClientCreateComponent,
    ClientEditComponent
  ],
  imports: [
    CommonModule,
    ClientsRoutingModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MdcListModule,
    MdcDialogModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MdcIconModule,
    MdcTypographyModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MdcTextFieldModule,
    SharedModule,
    MdcCardModule,
    MdcIconButtonModule,
    MatSelectModule,
    MatAutocompleteModule
  ],
  entryComponents: [
    ClientShowComponent,
    ClientCreateComponent,
    ClientEditComponent
  ]
})
export class ClientsModule {
}
