import {Injectable} from '@angular/core';
import {merge, Observable, of, Subject} from 'rxjs';
import {MatSort, SortDirection} from '@angular/material/sort';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {PaginationResource} from '../interfaces/pagination-resource';
import {ResourceService} from '../interfaces/resource-service';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  value = '';

  private subject: Subject<string> = new Subject<string>();

  constructor() {
  }

  search(value: string) {
    this.value = value;
    this.subject.next(value);
  }

  asObservable(): Observable<string> {
    return this.subject.asObservable();
  }

  index(
    sort: MatSort,
    paginator: MatPaginator,
    service: ResourceService,
    pagination?: (params: HttpParams) => Observable<PaginationResource>
  ): Observable<object[]> {
    sort.sortChange.subscribe(() => paginator.pageIndex = 0);

    return merge(sort.sortChange, paginator.page, this.asObservable())
      .pipe(
        startWith({}),
        switchMap(() => {
          const params = this.getHttpParams(sort.active, sort.direction, paginator.pageIndex + 1, this.value);

          return pagination ? pagination(params) : service.index(params);
        }),
        map((response: PaginationResource) => {
          paginator.length = response.meta.total;
          paginator.pageSize = response.meta.per_page;
          paginator.pageIndex = response.meta.current_page - 1;

          return response.data;
        }),
        catchError(() => of([]))
      );
  }

  getHttpParams(column: string, direction: SortDirection, page: number, search?: string): HttpParams {
    return new HttpParams()
      .set('column', column)
      .set('direction', direction)
      .set('search', search ? search : '')
      .set('page', page.toString());
  }
}
