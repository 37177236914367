import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private subject = new Subject<boolean>();

  constructor() {
  }

  observable(): Observable<boolean> {
    return this.subject.asObservable();
  }

  emit(loading: boolean): void {
    if (loading) {
      this.subject.next(loading);
    } else {
      setTimeout(() => this.subject.next(loading), 500);
    }
  }
}
