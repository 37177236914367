import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {User} from '../classes/user';
import {PermissionsResource} from '../interfaces/permissions-resource';
import {Role} from '../classes/role';
import {Permission} from '../classes/permission';
import {PermissionResource} from '../interfaces/permission-resource';
import {Client} from '../classes/client';
import {ResourceService} from '../interfaces/resource-service';
import {Storage} from '@ionic/storage';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements ResourceService {
  private storageKey = 'permissions';

  constructor(private http: HttpClient, private storage: Storage, private snackBar: MatSnackBar) {
  }

  getPermissions(): Promise<Permission[]> {
    return this.storage.get(this.storageKey);
  }

  setPermissions(permissions: Permission[]) {
    return this.storage.set(this.storageKey, permissions);
  }

  index(params: HttpParams): Observable<PermissionsResource> {
    return this.http.get<PermissionsResource>(`${environment.api}/api/permissions`, {params});
  }

  byUser(user: User, params: HttpParams): Observable<PermissionsResource> {
    return this.http.get<PermissionsResource>(`${environment.api}/api/users/${user.id}/permissions`, {params});
  }

  byRole(role: Role, params: HttpParams): Observable<PermissionsResource> {
    return this.http.get<PermissionsResource>(`${environment.api}/api/roles/${role.id}/permissions`, {params});
  }

  byClient(client: Client, params: HttpParams): Observable<PermissionsResource> {
    return this.http.get<PermissionsResource>(`${environment.api}/api/clients/${client.id}/permissions`, {params});
  }

  byClientAndMeViaRole(client: Client, params: HttpParams): Observable<PermissionsResource> {
    return this.http.get<PermissionsResource>(`${environment.api}/api/clients/${client.id}/users/me/permissions/via/role`, {params});
  }

  transformPermissionsToTreeFormat(permissions: Permission[]) {
    const permissionsTree: object = {};

    permissions.forEach((permission: Permission) => {
      const clientNode = permissionsTree.hasOwnProperty(permission.client.name)
        ? permissionsTree[permission.client.name]
        : {key: permission.client.id, children: {}};

      Object.assign(clientNode.children, {[permission.description]: {key: permission.id}});
      Object.assign(permissionsTree, {[permission.client.name]: clientNode});
    });

    return permissionsTree;
  }

  update(permission: Permission, client: Client): Observable<PermissionResource> {
    return this.http.put<PermissionResource>(`${environment.api}/api/clients/${client.id}/permissions/${permission.id}`, permission);
  }

  destroy(permission: Permission) {
    return this.http.delete<PermissionResource>(`${environment.api}/api/permissions/${permission.id}`);
  }

  store(permission: Permission, client: Client) {
    return this.http.post<PermissionResource>(`${environment.api}/api/clients/${client.id}/permissions`, permission);
  }

  hasPermission(permissionsToCheck: string | string[], showSnackBar: boolean = false): Promise<boolean> {
    return this.getPermissions().then((permissions: Permission[]) => {
      let hasPermission = false;

      permissionsToCheck = typeof permissionsToCheck === 'string' ? [permissionsToCheck] : permissionsToCheck;

      if (permissionsToCheck.filter(permission => permission.length > 0).length === 0) {
        return true;
      }

      if (permissions) {
        permissionsToCheck.forEach((permissionToCheck: string) => {
          const permissionFound = permissions.find((permission: Permission) => {
            return permission.name.toUpperCase() === permissionToCheck.toUpperCase();
          });

          if (permissionFound) {
            hasPermission = true;
          }
        });
      }

      if (!hasPermission && showSnackBar) {
        this.snackBar.open('Esta ação não é autorizada.', 'Fechar', {duration: 5000});
      }

      return hasPermission;
    });
  }
}
