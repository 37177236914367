import {Component, OnDestroy, OnInit} from '@angular/core';
import {MdcDialog, MdcDialogRef} from '@angular-mdc/web';
import {ClientService} from '../../../services/client.service';
import {DialogConfirmationComponent} from '../../../shared/dialog-confirmation/dialog-confirmation.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClientEditComponent} from '../client-edit/client-edit.component';
import {Dialog} from '../../../interfaces/dialog';
import {DialogHeaderActionsComponent} from '../../../interfaces/dialog-header-actions-component';
import {Client} from '../../../classes/client';
import {DialogComponent} from '../../../shared/dialog/dialog.component';
import {of, Subscription} from 'rxjs';
import {EStorage} from '../../../classes/e-storage';
import {EStorageService} from '../../../services/e-storage.service';
import { Municipio } from 'src/app/interfaces/municipio';

@Component({
  selector: 'app-client-show',
  templateUrl: './client-show.component.html',
  styleUrls: ['./client-show.component.scss']
})
export class ClientShowComponent implements OnInit, OnDestroy, Dialog, DialogHeaderActionsComponent {
  dialogRef: MdcDialogRef<Dialog>;
  data: { client: Client };
  client: Client;
  municipios: Municipio[] = [];

  protected subscriptions: Subscription[] = [];

  constructor(private clientService: ClientService,
              private dialog: MdcDialog,
              private eStorageService: EStorageService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {


    this.client = {...this.data.client};

    this.clientService.getMunicipios().subscribe((response) => {
      for (let i = 0; i < response['data'].length; i++) {
        if (response['data'][i].id != this.client.municipio) {
          continue;
        } else {
          this.municipios.push(response['data'][i]);
        }
      }
    });


  }

  

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  destroy(): void {
    this.subscriptions.push(this.dialog.open(DialogConfirmationComponent)
      .afterClosed()
      .subscribe(result => {
        if (result === 'confirm') {
          this.clientService.destroy(this.client)
            .subscribe(() => {
              let destroyResponse = of({});

              if (this.client.imageId) {
                const eStorage: EStorage = new EStorage();
                eStorage.id = this.client.imageId;
                destroyResponse = this.eStorageService.destroy(eStorage);
              }
              destroyResponse.subscribe();

              this.snackBar.open('Aplicação excluída com sucesso.', 'Fechar', {duration: 5000});
              this.dialogRef.close(true);
            });
        }
      }));
  }

  close(): void {
    this.dialogRef.close();
  }

  edit() {
    this.subscriptions.push(this.dialog.open(DialogComponent, {
      data: {
        component: ClientEditComponent,
        title: 'Editar aplicação',
        footerActions: true,
        info: {
          client: this.client
        }
      },
      autoFocus: false,
      scrollable: true,
      escapeToClose: true
    }).afterClosed().subscribe((success: boolean) => {
      if (success && typeof success === 'boolean') {
        this.dialogRef.close(true);
      }
    }));
  }
}
