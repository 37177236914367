import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TreeFlatNode} from '../../classes/tree-flat-node';
import {TreeNode} from '../../classes/tree-node';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {TreeService} from '../../services/tree.service';
import {debounceTime} from 'rxjs/operators';
import {MdcTextField} from '@angular-mdc/web';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  flatNodeMap = new Map<TreeFlatNode, TreeNode>();
  nestedNodeMap = new Map<TreeNode, TreeFlatNode>();
  treeControl: FlatTreeControl<TreeFlatNode>;
  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;
  checklistSelection = new SelectionModel<TreeFlatNode>(true);

  protected subscriptions: Subscription[] = [];

  @Input() data: object;
  @Input() checked: string[];

  @Output() synced = new EventEmitter<{ nodes: TreeFlatNode[], checked: boolean }>();

  @ViewChild('searchField', {static: false}) searchField: MdcTextField;

  constructor(private treeService: TreeService) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<TreeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.subscriptions.push(treeService.dataChange.subscribe(data => {
      this.dataSource.data = data;
    }));
  }

  ngOnInit(): void {
    this.treeService.initialize(this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checked = changes.checked.currentValue;
    this.check();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.searchField.input.pipe(
      debounceTime(500),
    ).subscribe((value: string) => {
      this.treeService.filter(value);
      this.check();
      this.treeControl.expandAll();
    }));
  }

  transformer = (node: TreeNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);

    const flatNode = existingNode && existingNode.item === node.item
      ? existingNode
      : new TreeFlatNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.key = node.key;

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    return flatNode;
  }

  getLevel = (node: TreeFlatNode) => node.level;

  isExpandable = (node: TreeFlatNode) => node.expandable;

  getChildren = (node: TreeNode): TreeNode[] => node.children;

  hasChild = (_: number, treeFlatNode: TreeFlatNode) => treeFlatNode.expandable;

  selectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);

    const descendants = this.treeControl.getDescendants(node);
    const isSelected = this.checklistSelection.isSelected(node);

    if (isSelected) {
      this.checklistSelection.select(...descendants);
      descendants.forEach((treeFlatNode: TreeFlatNode) => this.checked.push(treeFlatNode.key.toString()));
    } else {
      this.checklistSelection.deselect(...descendants);
      descendants.forEach((treeFlatNode: TreeFlatNode) => this.checked.splice(this.checked.indexOf(treeFlatNode.key.toString()), 1));
    }

    descendants.every(child => this.checklistSelection.isSelected(child));

    this.checkAllParentsSelection(node);
    this.synced.emit({nodes: descendants, checked: isSelected});
  }

  descendantsAllSelected(node: TreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);

    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  descendantsPartiallySelected(node: TreeFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));

    return result && !this.descendantsAllSelected(node);
  }

  leafSelectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);

    const isSelected = this.checklistSelection.isSelected(node);

    if (isSelected) {
      this.checked.push(node.key.toString());
    } else {
      this.checked.splice(this.checked.indexOf(node.key.toString()), 1);
    }

    this.synced.emit({nodes: [node], checked: isSelected});
  }

  private check() {
    this.checked.forEach(checked => {
      this.treeControl.dataNodes.filter(dataNode => dataNode.expandable)
        .forEach(parent => {
          this.treeControl.getDescendants(parent)
            .filter(dataNode => dataNode.key.toString() === checked.toString())
            .forEach(dataNode => {
              this.checklistSelection.select(dataNode);
              this.checkAllParentsSelection(dataNode);
            });
        });
    });
  }

  checkAllParentsSelection(node: TreeFlatNode): void {
    let parent: TreeFlatNode | null = this.getParentNode(node);

    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeSelection(node: TreeFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child => this.checklistSelection.isSelected(child));

    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  getParentNode(node: TreeFlatNode): TreeFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }

    return null;
  }
}
