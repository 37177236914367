import {Role} from './role';

export class Client {
  id: number;
  name: string;
  secret: string;
  redirect: Location;
  revoked: boolean;
  createdAt: string;
  updatedAt: string;
  image: string | ArrayBuffer;
  imageId: number;
  role: Role;
  urlAplicacao: string;
  visibilidadeMenu: boolean;
  municipio: number;
  linkInterno: boolean;
}
