import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {LoadingService} from '../../services/loading.service';
import {CustomValidators} from '../../validators/validators';
import {User} from '../../classes/user';
import {AuthComponent} from '../auth.component';
import {ResetPasswordService} from '../../services/reset-password.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Client} from '../../classes/client';
import {ClientService} from '../../services/client.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends AuthComponent implements OnInit {
  user: User = new User();
  userForm: FormGroup;
  visibility = false;
  type = 'password';
  email: string;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userService: UserService,
              private formBuilder: FormBuilder,
              private resetPasswordService: ResetPasswordService,
              private snackBar: MatSnackBar,
              private clientService: ClientService,
              loadingService: LoadingService) {
    super(loadingService);
  }

  get password(): AbstractControl {
    return this.userForm.get('password');
  }

  get password_confirmation(): AbstractControl {
    return this.userForm.get('password_confirmation');
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      this.subscriptions.push(this.clientService.nameByQueryParam(params).subscribe((client: Client) => {
        this.clientService.setCurrentClient(client);
      }, () => this.router.navigate(['/auth/email'])));
    }));

    this.route.queryParams.subscribe(params => {
      this.user.email = params.email;
    });

    this.userForm = this.formBuilder.group({
      password: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      password_confirmation: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
    }, {
      validator: CustomValidators.passwordConfirmation
    });
  }

  next(): void {
    this.userForm.markAllAsTouched();

    if (this.userForm.valid) {
      this.subscriptions.push(this.resetPasswordService.reset(
        this.user.email,
        this.password.value,
        this.password_confirmation.value,
        this.route.snapshot.paramMap.get('token')
      ).subscribe(() => {
        this.userService.setCurrentUser(this.user);
        this.snackBar.open('Senha redefinida com sucesso.', 'Fechar', {duration: 5000});
        this.router.navigate(['/auth/password']);
      }));
    }
  }

  changeVisibility(): void {
    this.visibility = !this.visibility;
    this.type = 'password';

    if (this.visibility) {
      this.type = 'text';
    }
  }
}
