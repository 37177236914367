import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private http: HttpClient, private storage: Storage) {
  }

  getRedirect(): Observable<string | Location> {
    return from(this.storage.get('redirect'));
  }

  setRedirect(redirect: string): Promise<any> {
    return this.storage.set('redirect', redirect);
  }

  hasRedirect(): Observable<boolean> {
    return this.getRedirect().pipe(map((redirect: string) => redirect !== ''));
  }
}
