import {Component, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../../classes/client';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MdcDialogRef} from '@angular-mdc/web';
import {ClientService} from '../../../services/client.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClientResource} from '../../../interfaces/client-resource';
import {Dialog} from '../../../interfaces/dialog';
import {DialogFooterActionsComponent} from '../../../interfaces/dialog-footer-actions-component';
import {DialogComponent} from '../../../shared/dialog/dialog.component';
import {EStorageResource} from '../../../interfaces/e-storage-resource';
import {EStorageService} from '../../../services/e-storage.service';
import {EStorage} from '../../../classes/e-storage';
import {Observable, of, Subscription} from 'rxjs';
import {Role} from '../../../classes/role';
import {RolesResource} from '../../../interfaces/roles-resource';
import {map, startWith} from 'rxjs/operators';
import {RoleService} from '../../../services/role.service';
import {SearchService} from '../../../services/search.service';
import {RoleResource} from '../../../interfaces/role-resource';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit, OnDestroy, Dialog, DialogFooterActionsComponent {
  image: File;
  client: Client;
  roles: Role[] = [];
  clientEdit: Client;
  clientForm: FormGroup;
  data: { client: Client };
  dialogRef: MdcDialogRef<DialogComponent>;
  filteredOptions: Observable<Role[]>;

  protected subscriptions: Subscription[] = [];

  constructor(private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private clientService: ClientService,
              private roleService: RoleService,
              private searchService: SearchService,
              private eStorageService: EStorageService) {
  }

  get name(): AbstractControl {
    return this.clientForm.get('name');
  }

  get redirect(): AbstractControl {
    return this.clientForm.get('redirect');
  }

  get secret(): AbstractControl {
    return this.clientForm.get('secret');
  }

  get role(): AbstractControl {
    return this.clientForm.get('role');
  }

  get urlaplicacao(): AbstractControl {
    return this.clientForm.get('urlaplicacao');
  }

  get visibilidadeMenu(): AbstractControl {
    return this.clientForm.get('visibilidadeMenu');
  }

  ngOnInit() {
    this.client = {...this.data.client};
    this.clientEdit = {...this.data.client};

    this.clientForm = this.formBuilder.group({
      name: [this.clientEdit.name, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      redirect: [this.clientEdit.redirect, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      urlaplicacao: [this.clientEdit.urlAplicacao, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      visibilidadeMenu: [this.clientEdit.visibilidadeMenu, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      secret: [this.clientEdit.secret],
      role: [this.clientEdit.role, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
    });

    this.subscriptions.push(this.roleService.index(this.searchService.getHttpParams(
      'name',
      'asc',
      0
    )).subscribe((response: RolesResource) => {
      this.roles = response.data;

      this.filteredOptions = this.role.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.roles.slice())
        );

      this.role.setValue(this.clientEdit.role);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  save(): void {
    this.clientForm.markAllAsTouched();

    if (this.clientForm.valid) {
      let destroyResponse = of({});
      let storeResponse = of({
        data: {
          id: this.clientEdit.imageId,
          url: this.clientEdit.image
        }
      });

      if (this.clientEdit.image !== this.client.image) {
        if (this.client.imageId) {
          const eStorage: EStorage = new EStorage();
          eStorage.id = this.client.imageId;

          destroyResponse = this.eStorageService.destroy(eStorage);
        }

        if (this.image) {
          const formData = new FormData();
          formData.append('file', this.image);
          formData.append('visibility', 'public');

          storeResponse = this.eStorageService.store(formData);
        }
      }

      let roleStoreResponse;
      let message = 'Detalhes da aplicação atualizados.';

      if (typeof this.role.value === 'string') {
        const role: Role = new Role();
        role.name = this.role.value;

        roleStoreResponse = this.roleService.store(role);

        message = `${message} Lembre-se de atribuir permissões ao perfil criado.`;
      } else {
        roleStoreResponse = of({
          data: this.role.value
        });
      }

      this.subscriptions.push(roleStoreResponse.subscribe((roleResource: RoleResource) => {
        this.subscriptions.push(storeResponse.subscribe((response: EStorageResource) => {
          const client: Client = new Client();
          client.id = this.clientEdit.id;
          client.name = this.name.value;
          client.redirect = this.redirect.value;
          client.revoked = this.clientEdit.revoked;
          client.image = response.data.url;
          client.imageId = response.data.id;
          client.role = roleResource.data;
          client.urlAplicacao = this.urlaplicacao.value
          client.visibilidadeMenu = this.visibilidadeMenu.value

          this.subscriptions.push(this.clientService.update(client).subscribe(() => {
            this.subscriptions.push(destroyResponse.subscribe(() => {
                this.snackBar.open(message, 'Fechar', {duration: 5000});
                this.dialogRef.close(true);
            }));
          }));
        }));
      }));
    }
  }

  refresh() {
    const client: Client = new Client();
    client.id = this.client.id;
    client.name = this.client.name;
    client.redirect = this.client.redirect;
    client.revoked = this.client.revoked;
    client.image = this.client.image;
    client.imageId = this.client.imageId;
    client.role = this.client.role;
    client.urlAplicacao = this.urlaplicacao.value
    client.visibilidadeMenu = this.visibilidadeMenu.value

    this.subscriptions.push(this.clientService.update(client, true)
      .subscribe((response: ClientResource) => this.secret.setValue(response.data.secret)));
  }

  removeImage() {
    this.image = null;
    this.clientEdit.image = null;
    this.clientEdit.imageId = null;
  }

  addImage(event: any) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];

      if (file.type.match(/image\/*/) == null) {
        this.snackBar.open('É necessário selecionar uma imagem.', 'Fechar', {duration: 5000});
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(this.image = file);
      reader.onload = () => this.clientEdit.image = reader.result;
    }
  }

  displayFn(role?: Role): string | undefined {
    return role ? role.name : undefined;
  }

  private _filter(name: string): Role[] {
    const filterValue = name.toLowerCase();
    return this.roles.filter(role => role.name.toLowerCase().includes(filterValue));
  }
}
