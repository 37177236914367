import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../classes/user';
import {UserService} from '../../services/user.service';
import {ForgotPasswordService} from '../../services/forgot-password.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClientService} from '../../services/client.service';
import {Client} from '../../classes/client';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  user: User = new User();

  private subscriptions: Subscription[] = [];

  constructor(private userService: UserService,
              private clientService: ClientService,
              private forgotPasswordService: ForgotPasswordService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.userService.getCurrentUser().then((user: User) => this.user = user);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  send() {
    this.clientService.getCurrentClient().then((client: Client) => {
      this.subscriptions.push(this.forgotPasswordService.sendResetLinkEmail(this.user, client).subscribe(() => {
        this.snackBar.open('E-mail enviado com sucesso.', 'Fechar', {duration: 5000});
      }));
    });
  }
}
