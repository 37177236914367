import {Component, OnDestroy, OnInit} from '@angular/core';
import {MdcDialog, MdcDialogRef} from '@angular-mdc/web';
import {User} from '../../../classes/user';
import {Role} from '../../../classes/role';
import {RoleService} from '../../../services/role.service';
import {RolesResource} from '../../../interfaces/roles-resource';
import {UserService} from '../../../services/user.service';
import {Subscription} from 'rxjs';
import {ClientService} from '../../../services/client.service';
import {Permission} from '../../../classes/permission';
import {PermissionsResource} from '../../../interfaces/permissions-resource';
import {PermissionService} from '../../../services/permission.service';
import {TreeFlatNode} from '../../../classes/tree-flat-node';
import {DialogConfirmationComponent} from '../../../shared/dialog-confirmation/dialog-confirmation.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserEditComponent} from '../user-edit/user-edit.component';
import {Dialog} from '../../../interfaces/dialog';
import {DialogHeaderActionsComponent} from '../../../interfaces/dialog-header-actions-component';
import {DialogComponent} from '../../../shared/dialog/dialog.component';
import {SearchService} from '../../../services/search.service';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-show',
  templateUrl: './user-show.component.html',
  styleUrls: ['./user-show.component.scss']
})
export class UserShowComponent implements OnInit, OnDestroy, Dialog, DialogHeaderActionsComponent {
  dialogRef: MdcDialogRef<Dialog, any>;
  user: User;
  roles: Role[] = [];
  permissions: Permission[] = [];
  treeData: object = {};
  checkedPermissions: string[] = [];
  data: { user: User, roles: Role[], permissions: Permission[] };

  private userRoles: Role[] = [];

  protected subscriptions: Subscription[] = [];

  constructor(private roleService: RoleService,
              private userService: UserService,
              private clientService: ClientService,
              private authService: AuthService,
              private permissionService: PermissionService,
              private searchService: SearchService,
              private dialog: MdcDialog,
              private router: Router,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.user = {...this.data.user};
    this.roles = this.data.roles;
    this.permissions = this.data.permissions;
    this.treeData = this.permissionService.transformPermissionsToTreeFormat(this.permissions);

    this.subscriptions.push(this.roleService.byUser(this.user)
      .subscribe((resource: RolesResource) => this.userRoles = resource.data));

    this.subscriptions.push(this.permissionService.byUser(this.user, this.searchService.getHttpParams(
      'description',
      'asc',
      0
    )).subscribe((resource: PermissionsResource) => {
      this.checkedPermissions = resource.data.map(permission => permission.id.toString());
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  syncRoles(role: Role) {
    if (this.hasRole(role)) {
      this.userRoles = this.userRoles.filter(userRole => userRole.id !== role.id);
    } else {
      this.userRoles.push(role);
    }

    this.subscriptions.push(
      this.userService.syncRoles(this.user, this.userRoles.map(userRole => userRole.id)).subscribe()
    );
  }

  edit() {
    this.subscriptions.push(this.dialog.open(DialogComponent, {
      data: {
        info: {user: this.user},
        component: UserEditComponent,
        footerActions: true,
        title: 'Editar usuário'
      },
      autoFocus: false,
      scrollable: true,
      escapeToClose: true
    }).afterClosed().subscribe((success: boolean) => {
      if (success && typeof success === 'boolean') {
        this.dialogRef.close(true);
      }
    }));
  }

  destroy(): void {
    this.subscriptions.push(this.dialog.open(DialogConfirmationComponent)
      .afterClosed()
      .subscribe(result => {
        if (result === 'confirm') {
          this.subscriptions.push(this.userService.destroy(this.user).subscribe(() => {
            this.snackBar.open('Usuário excluído com sucesso.', 'Fechar', {duration: 5000});
            this.dialogRef.close(true);
            this.userService.getCurrentUser().then((user: User) => {
              if (user.id === this.user.id) {
                this.authService.logout(false).then(() => this.router.navigate(['/auth/email']));
              }
            });
          }));
        }
      }));
  }

  close() {
    this.dialogRef.close();
  }

  syncPermissions(synced: { nodes: TreeFlatNode[]; checked: boolean }) {
    const permissions = synced.nodes.map(node => node.key);
    const subscription = synced.checked
      ? this.userService.syncAddPermissions(this.user, permissions)
      : this.userService.syncRemovePermissions(this.user, permissions);

    this.subscriptions.push(subscription.subscribe());
  }

  hasRole(role: Role) {
    return this.userRoles.filter(userRole => userRole.id === role.id).length > 0;
  }
}
