import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {RedirectService} from '../services/redirect.service';
import {Client} from '../classes/client';
import {ClientService} from '../services/client.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService,
              private redirectService: RedirectService,
              private clientService: ClientService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated().then((authenticated: boolean) => {
      if (!authenticated) {
        this.redirectService.setRedirect(state.url);
        this.clientService.getCurrentClient().then((client: Client) => {
          this.router.navigate(['/auth/email'], {
            queryParams: {
              client_id: next.params.user,
              municipio: next.queryParams.municipio,
            }
          });
        });
      }

      return authenticated;
    });
  }
}
