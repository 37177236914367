import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {User} from '../classes/user';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Storage} from '@ionic/storage';
import {Client} from '../classes/client';
import {map} from 'rxjs/operators';
import {Auth} from '../classes/auth';
import {UsersResource} from '../interfaces/users-resource';
import {UserResource} from '../interfaces/user-resource';
import {ResourceService} from '../interfaces/resource-service';

@Injectable({
  providedIn: 'root'
})
export class UserService implements ResourceService {
  userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  private storageKey = 'currentUser';

  constructor(private http: HttpClient, private storage: Storage) {
  }

  getCurrentUser(): Promise<User> {
    return this.storage.get(this.storageKey);
  }

  setCurrentUser(user: User): Promise<any> {
    this.userSubject.next(user);

    return this.storage.set(this.storageKey, user);
  }

  emailExists(email: string, clientId: number, mobile: boolean) {
    return this.http.post(`${environment.api}/api/email/exists`, {email, clientId, mobile});
  }

  insereLog(email: string, id_usuario: number, id_client: number, message: string) {
    return this.http.post(`${environment.api}/log/insere`, {email, id_usuario, id_client, message});
  }

  store(user: User, client: Client, token?: string) {
    return this.http.post(`${environment.api}/api/users`, {...user, token}, {
      params: new HttpParams().set('client_id', client.id.toString())
    }).pipe(
      map((response: { data: User }) => response.data)
    );
  }

  authorize(user: User, client: Client, token?: string) {
    return this.http.post(`${environment.api}/api/authorize`, {
      email: user.email,
      password: user.password,
      municipio: user.municipio,
      token
    }, {
      params: new HttpParams().set('client_id', client.id.toString()).set('response_type', 'code'),
    });
  }

  login(user: User, client: Client) {
    let email = user.email.toLocaleLowerCase();
    return this.http.post(`${environment.api}/oauth/token`, {
      client_id: client.id,
      client_secret: client.secret,
      grant_type: 'password',
      username: email,
      password: user.password,
      municipio: user.municipio
    }).pipe(
      map((response: { access_token: string, token_type: string }) => {
        const auth = new Auth();
        auth.accessToken = response.access_token;
        auth.tokenType = response.token_type;

        return auth;
      })
    );
  }

  hasVerifiedEmail(user: User): boolean {
    return !!user && !!user.emailVerifiedAt;
  }

  resendEmail(client: Client) {
    return this.http.post(`${environment.api}/api/email/resend`, null, {
      params: new HttpParams().set('client_id', client.id.toString())
    });
  }

  verify(url: string): Observable<User> {
    return this.http.get(`${environment.api}${url}`).pipe(
      map((response: { verified: boolean, user: User }) => response.user)
    );
  }

  index(params: HttpParams) {
    return this.http.get<UsersResource>(`${environment.api}/api/users`, {params});
  }

  syncRoles(user: User, roles: number[]) {
    return this.http.post(`${environment.api}/api/users/${user.id}/roles/sync`, {roles});
  }

  syncAddPermissions(user: User, permissions: string[]) {
    return this.http.post(`${environment.api}/api/users/${user.id}/permissions/sync/add`, {permissions});
  }

  syncRemovePermissions(user: User, permissions: string[]) {
    return this.http.post(`${environment.api}/api/users/${user.id}/permissions/sync/remove`, {permissions});
  }

  destroy(user: User): Observable<UserResource> {
    return this.http.delete<UserResource>(`${environment.api}/api/users/${user.id}`);
  }

  update(user: User): Observable<UserResource> {
    return this.http.put<UserResource>(`${environment.api}/api/users/${user.id}`, user);
  }

  primeiroAcesso(user: User): Observable<any> {
    return this.http.post<any>(`${environment.api}/users/primeiroacesso`, user);
  }

  buscaUserNoMunicipio(municipio, email): Observable<any>
  {
    return this.http.get<any>(`${environment.api}/users/buscapormunicipio/${municipio}/${email}`);
  }
}
