import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionService} from '../services/permission.service';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective {

  private permissions: string | string[] = [];

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private permissionService: PermissionService
  ) {
  }

  @Input() set appPermission(permissions: string | string[]) {
    this.permissions = permissions;
    this.updateView();
  }

  private updateView() {
    this.permissionService.hasPermission(this.permissions).then((checked: boolean) => {
      if (checked) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
