import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MdcDialog, MdcDrawer, MdcList, MdcTextField} from '@angular-mdc/web';
import {NavigationEnd, Router} from '@angular/router';
import {LoadingService} from '../services/loading.service';
import {MediaObserver} from '@angular/flex-layout';
import {NewButtonClickService} from '../services/new-button-click.service';
import {SearchService} from '../services/search.service';
import {debounceTime} from 'rxjs/operators';
import {animate, group, state, style, transition, trigger} from '@angular/animations';
import {SettingsComponent} from './settings/settings.component';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {UserShowComponent} from './users/user-show/user-show.component';
import {UserService} from '../services/user.service';
import {User} from '../classes/user';
import {RolesResource} from '../interfaces/roles-resource';
import {PermissionsResource} from '../interfaces/permissions-resource';
import {RoleService} from '../services/role.service';
import {PermissionService} from '../services/permission.service';
import {Role} from '../classes/role';
import {Permission} from '../classes/permission';
import {AuthService} from '../services/auth.service';
import {Client} from '../classes/client';
import {ClientService} from '../services/client.service';
import {ClientsResource} from '../interfaces/clients-resource';
import {Subscription} from 'rxjs';
import { PrimeiroacessoComponent } from './primeiroacesso/primeiroacesso.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('fade', [
      state('hide', style({
        opacity: 0,
        height: 0
      })),
      state('show', style({
        opacity: 1,
        height: '*'
      })),
      transition('hide => show', [
        group([
          animate('200ms ease-in-out', style({
            opacity: 1
          })),
          animate('200ms ease-in-out', style({
            height: '*'
          }))
        ])
      ]),
      transition('show => hide', [
        group([
          animate('200ms ease-in-out', style({
            opacity: 0
          })),
          animate('200ms ease-in-out', style({
            height: 0
          }))
        ])
      ])
    ])
  ]
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  loading = false;
  disabled = true;
  searching = false;
  searchBar = 'hide';
  hasSearch = 'show';
  menuBar = 'show';
  showApps = false;
  drawerOpen = false;
  showMenu = false;
  clients: Client[] = [];
  currentUser: User = new User();
  routes = [
    {label: 'Início', icon: 'home', activated: true, route: '/dashboard', search: false, permission: ''}
  ];
  permissionsMenu = [
    {
      label: 'Aplicações',
      icon: 'dynamic_feed',
      activated: false,
      route: '/dashboard/clients',
      search: true,
      permission: 'clients:index'
    },
    {
      label: 'Usuários',
      icon: 'supervisor_account',
      activated: false,
      route: '/dashboard/users',
      search: true,
      permission: 'users:index'
    },
    {
      label: 'Perfis',
      icon: 'account_circle',
      activated: false,
      route: '/dashboard/roles',
      search: true,
      permission: 'roles:index'
    },
    {
      label: 'Permissões',
      icon: 'lock',
      activated: false,
      route: '/dashboard/permissions',
      search: true,
      permission: 'permissions:index'
    },
    {
      label: 'Estados',
      icon: 'account_balance',
      activated: false,
      route: '/dashboard/estados',
      search: true,
      permission: 'states:index'
    },
    {
      label: 'Municípios',
      icon: 'home',
      activated: false,
      route: '/dashboard/cities',
      search: true,
      permission: 'county:index'
    },
    {
      label: 'Configuração',
      icon: 'dns',
      activated: false,
      route: '/dashboard/config',
      search: true,
      permission: 'config:index'
    }
  ];

  protected subscriptions: Subscription[] = [];

  @ViewChild('appDrawer', {static: false}) appDrawer: MdcDrawer;
  @ViewChild('searchField', {static: false}) searchField: MdcTextField;
  @ViewChild('searchFieldMobile', {static: false}) searchFieldMobile: MdcTextField;
  @ViewChild('drawerList', {static: false}) drawerList: MdcList;

  constructor(private router: Router,
              private loadingService: LoadingService,
              private newButtonClickService: NewButtonClickService,
              private searchService: SearchService,
              private userService: UserService,
              private roleService: RoleService,
              private clientService: ClientService,
              private permissionService: PermissionService,
              private authService: AuthService,
              private dialog: MdcDialog,
              public mediaObserver: MediaObserver) {
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateRoute();
      }
    }));
  }

  ngOnInit() {
    this.subscriptions.push(this.mediaObserver.asObservable().subscribe());
    this.subscriptions.push(this.loadingService.observable().subscribe(loading => this.loading = loading));
    this.subscriptions.push(this.newButtonClickService.disabledObservable().subscribe(disabled => this.disabled = disabled));
    this.userService.getCurrentUser().then((user: User) => {
      this.currentUser = user

      // if (this.currentUser.primeiroacesso) {
      //   this.dialog.open(DialogComponent, {
      //     scrollable: true,
      //     escapeToClose: false,
      //     clickOutsideToClose: false,
      //     data: {
      //       info: {
      //         user: this.currentUser,
      //       },
      //       component: PrimeiroacessoComponent,
      //       title: 'Primeiro Acesso - Troque sua senha',
      //       footerActions: true
      //     }
      //   });
      // }
    });
    this.subscriptions.push(this.userService.userSubject.asObservable().subscribe((user: User) => this.currentUser = user));
    this.subscriptions.push(this.clientService.clients.subscribe((clients: Client[]) => this.clients = clients));
    this.subscriptions.push(this.clientService.indexBasic(this.searchService.getHttpParams('name', 'asc', 0))
      .subscribe((response: ClientsResource) => this.clientService.clients.next(response.data)));

    const promises = [];

    this.permissionsMenu.forEach((menu) => {
      promises.push(this.permissionService.hasPermission(menu.permission).then((checked: boolean) => {
        if (checked) {
          this.routes.push(menu);
        }
      }));
    });

    Promise.all(promises).then(() => {
      if (this.routes.length > 1) {
        this.drawerOpen =  !this.mediaObserver.isActive('lt-md');
        this.showMenu = true;
      }

      this.activateRoute();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(this.searchField.input.pipe(
      debounceTime(500),
    ).subscribe((value: string) => this.searchService.search(value)));

    if (this.mediaObserver.isActive('lt-md')) {
      this.subscriptions.push(this.searchFieldMobile.input.pipe(
        debounceTime(500),
      ).subscribe((value: string) => this.searchService.search(value)));
    }
  }

  goTo(path: string) {
    this.router.navigate([path]).then(() => {
      this.activateRoute();
      this.searchField.value = '';
      this.searchService.value = '';

      if (this.mediaObserver.isActive('lt-md')) {
        this.appDrawer.open = false;
        this.drawerOpen = false;
      }

      this.routes.filter(route => route.route !== window.location.pathname).map(route => route.activated = false);
    });
  }

  new() {
    this.newButtonClickService.emit(true);
  }

  toggleSearch() {
    this.searching = !this.searching;
    this.searchBar = this.searching ? 'show' : 'hide';
    this.menuBar = this.searching ? 'hide' : 'show';

    if (!this.searching) {
      this.searchFieldMobile.setValue('');
      this.searchFieldMobile.input.emit('');
    }
  }

  goToAccount() {
    let user: User;
    let roles: Role[] = [];
    let permissions: Permission[] = [];

    this.userService.getCurrentUser().then((currentUser: User) => {
      user = currentUser;

      return this.permissionService.hasPermission('roles:index').then((checked: boolean) => {
        if (checked) {
          return this.roleService.index(this.searchService.getHttpParams('name', 'asc', 0)).toPromise();
        } else {
          return new Promise(resolve => resolve({data: []}));
        }
      });

    }).then((resource: RolesResource) => {
      roles = resource.data;

      return this.permissionService.hasPermission('permissions:index').then((checked: boolean) => {
        if (checked) {
          return this.permissionService.index(this.searchService.getHttpParams('name', 'asc', 0)).toPromise();
        } else {
          return new Promise(resolve => resolve({data: []}));
        }
      });
    }).then((resource: PermissionsResource) => {
      permissions = resource.data;

      this.dialog.open(DialogComponent, {
        data: {
          info: {
            user,
            roles,
            permissions
          },
          component: UserShowComponent,
          title: user.name,
          headerActions: true,
          editPermission: 'users:edit',
          destroyPermission: 'users:destroy',
        },
        scrollable: true,
        escapeToClose: true,
        autoFocus: false,
      });
    });
  }

  goToSettings() {
    this.dialog.open(DialogComponent, {
      scrollable: true,
      escapeToClose: true,
      clickOutsideToClose: true,
      data: {
        component: SettingsComponent,
        title: 'Configurações',
        footerActions: true
      }
    });
  }

  goToClient(client: Client) {
    this.subscriptions.push(this.authService.authorize(client).subscribe((response: { redirect: Location }) => {
      window.open(response.redirect.toString(), '_blank');
    }));
  }

  private activateRoute() {
    this.routes.map(route => route.activated = false);

    const currentRoute = this.routes
      .find(route => route.route === window.location.pathname);

    if (currentRoute) {

      this.showApps = currentRoute.route !== '/dashboard';

      this.hasSearch = currentRoute.search ? 'show' : 'hide';
      currentRoute.activated = true;
    }
  }

  logout() {
    this.authService.logout().then(() => this.router.navigate(['/auth/email']));
  }
}
