import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map, mergeMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {EStorageResource} from '../interfaces/e-storage-resource';
import {EStorage} from '../classes/e-storage';

@Injectable({
  providedIn: 'root'
})
export class EStorageService {
  private accessToken: string;
  private http: HttpClient;

  constructor(private backend: HttpBackend) {
    this.http = new HttpClient(backend);
  }

  authenticate(): Observable<{ access_token: string }> {
    if (this.accessToken) {
      return of({access_token: this.accessToken});
    }

    return this.http.post(`${environment.eStorageUrl}/oauth/token`, {
      client_id: environment.eStorageId,
      client_secret: environment.eStorageSecret,
      grant_type: 'client_credentials'
    }).pipe(
      tap((response: { access_token: string }) => this.accessToken = response.access_token)
    );
  }

  store(formData: FormData): Observable<EStorageResource> {
    return this.authenticate()
      .pipe(
        mergeMap(() => this.http.post<EStorageResource>(`${environment.eStorageUrl}/api/files`, formData, {
          headers: this.getHeaders()
        }).pipe(
          map((response: EStorageResource) => {
            response.data.url = `${environment.eStorageUrl}/${response.data.url}`;

            return response;
          })
        ))
      );
  }

  destroy(eStorage: EStorage): Observable<EStorageResource> {
    return this.authenticate()
      .pipe(
        mergeMap(() => this.http.delete<EStorageResource>(`${environment.eStorageUrl}/api/files/${eStorage.id}`, {
          headers: this.getHeaders()
        }))
      );
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${this.accessToken}`);
  }
}
