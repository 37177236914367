import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
  MdcCheckboxModule,
  MdcDialogModule,
  MdcIconButtonModule,
  MdcListModule,
  MdcTypographyModule
} from '@angular-mdc/web';
import {MatPaginatorModule} from '@angular/material/paginator';
import {UsersRoutingModule} from './users-routing.module';
import {UsersComponent} from './users.component';
import {NgxMaskModule} from 'ngx-mask';
import {UserShowComponent} from './user-show/user-show.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SharedModule} from '../../shared/shared.module';
import {MatExpansionModule} from '@angular/material/expansion';
import {UserEditComponent} from './user-edit/user-edit.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [UsersComponent, UserShowComponent, UserEditComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    MatTableModule,
    MatSortModule,
    FlexLayoutModule,
    MdcTypographyModule,
    MatPaginatorModule,
    NgxMaskModule,
    MdcDialogModule,
    MdcIconButtonModule,
    MatIconModule,
    MatButtonModule,
    MdcListModule,
    MatTreeModule,
    MatCheckboxModule,
    MdcCheckboxModule,
    SharedModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  entryComponents: [
    UserShowComponent,
    UserEditComponent
  ],
  exports: [UserShowComponent]
})
export class UsersModule {
}
