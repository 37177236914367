import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MediaObserver} from '@angular/flex-layout';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {User} from '../../classes/user';
import {UserService} from '../../services/user.service';
import {Subscription} from 'rxjs';
import {MdcDialog} from '@angular-mdc/web';
import {UserShowComponent} from './user-show/user-show.component';
import {Role} from '../../classes/role';
import {RoleService} from '../../services/role.service';
import {RolesResource} from '../../interfaces/roles-resource';
import {Permission} from '../../classes/permission';
import {PermissionService} from '../../services/permission.service';
import {PermissionsResource} from '../../interfaces/permissions-resource';
import {NewButtonClickService} from '../../services/new-button-click.service';
import {SearchService} from '../../services/search.service';
import {DialogComponent} from '../../shared/dialog/dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy, AfterViewInit {
  users: User[] = [];
  columns: string[] = [];

  private roles: Role[] = [];
  private permissions: Permission[] = [];

  protected subscriptions: Subscription[] = [];

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  constructor(private dialog: MdcDialog,
              private roleService: RoleService,
              private permissionService: PermissionService,
              private newButtonClickService: NewButtonClickService,
              private searchService: SearchService,
              private userService: UserService,
              public mediaObserver: MediaObserver) {
  }

  ngOnInit() {
    this.subscriptions.push(this.mediaObserver.asObservable().subscribe(() => this.responsiveColumns()));

    this.permissionService.hasPermission('roles:index').then((checked: boolean) => {
      if (checked) {
        this.subscriptions.push(this.roleService.index(this.searchService.getHttpParams('name', 'asc', 0))
          .subscribe((resource: RolesResource) => this.roles = resource.data));
      }
    });

    this.permissionService.hasPermission('permissions:index').then((checked: boolean) => {
      if (checked) {
        this.subscriptions.push(this.permissionService.index(this.searchService.getHttpParams('name', 'asc', 0))
          .subscribe((resource: PermissionsResource) => this.permissions = resource.data));
      }
    });

    this.newButtonClickService.toggleDisabled(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    this.index();
  }

  show(user: User) {
    this.permissionService.hasPermission('users:show', true)
      .then((checked: boolean) => {
        if (checked) {
          this.subscriptions.push(this.dialog.open(DialogComponent, {
            data: {
              info: {
                user,
                roles: this.roles,
                permissions: this.permissions
              },
              component: UserShowComponent,
              title: user.name,
              headerActions: true,
              editPermission: 'users:edit',
              destroyPermission: 'users:destroy',
            },
            scrollable: true,
            escapeToClose: true,
            autoFocus: false,
          }).afterClosed().subscribe((success: boolean) => {
            if (success && typeof success === 'boolean') {
              this.index();
            }
          }));
        }
      });
  }

  private index() {
    this.subscriptions.push(this.searchService.index(this.sort, this.paginator, this.userService)
      .subscribe((users: User[]) => this.users = users));
  }

  private responsiveColumns() {
    this.columns = ['name'];

    if (this.mediaObserver.isActive('gt-sm')) {
      this.columns.push('email');
    }

    if (this.mediaObserver.isActive('gt-md')) {
      this.columns.push('cpf');
    }

    if (this.mediaObserver.isActive('gt-lg')) {
      this.columns.push('updated_at');
    }
  }
}
