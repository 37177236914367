import {Permission} from './permission';

export class User {
  id: number;
  name: string;
  email: string;
  cpf: string;
  password: string;
  emailVerifiedAt: string;
  permissions?: Permission[];
  municipio: number;
  primeiroacesso: boolean;
}
