import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TreeNode} from '../classes/tree-node';

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  dataChange = new BehaviorSubject<TreeNode[]>([]);
  originalData: object;

  constructor() {
  }

  get data(): TreeNode[] {
    return this.dataChange.value;
  }

  initialize(data: object) {
    this.originalData = Object.assign({}, data);
    this.dataChange.next(this.buildFileTree(data, 0));
  }

  buildFileTree(obj: { [key: string]: any }, level: number): TreeNode[] {
    return Object.keys(obj).reduce<TreeNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new TreeNode();
      node.item = key;
      node.key = value.key;

      if (value.hasOwnProperty('children') && value.children != null) {
        if (typeof value.children === 'object') {
          node.children = this.buildFileTree(value.children, level + 1);
        } else {
          node.item = value.children;
        }
      }

      return accumulator.concat(node);
    }, []);
  }

  filter(search: string) {
    let filteredData = {};
    const data = Object.assign({}, this.originalData);

    if (search) {
      Object.keys(data).map((client) => {
        filteredData[client] = Object.assign({}, data[client]);
        const children = Object.assign({}, data[client].children);

        filteredData[client].children = Object.keys(children)
          .filter(permissionName => permissionName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
          .reduce((permissions, permissionKey) => {
            permissions[permissionKey] = children[permissionKey];
            return permissions;
          }, {});

        if (Object.keys(filteredData[client].children).length === 0) {
          delete filteredData[client];
        }
      });
    } else {
      filteredData = Object.assign({}, this.originalData);
    }

    this.dataChange.next(this.buildFileTree(filteredData, 0));
  }
}
