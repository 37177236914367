import {Injectable} from '@angular/core';
import {LdapSettings} from '../classes/ldap-settings';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {LdapSettingsResource} from '../interfaces/ldap-settings-resource';

@Injectable({
  providedIn: 'root'
})
export class LdapSettingsService {

  constructor(private http: HttpClient) {
  }

  update(ldapSettings: LdapSettings): Observable<LdapSettingsResource> {
    return this.http.put<LdapSettingsResource>(`${environment.api}/api/settings/ldap`, {
      ...ldapSettings,
      base_dn: ldapSettings.baseDn,
      use_ssl: ldapSettings.useSsl,
      use_tls: ldapSettings.useTls
    });
  }

  show(): Observable<LdapSettingsResource> {
    return this.http.get<LdapSettingsResource>(`${environment.api}/api/settings/ldap`);
  }
}
