import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../../classes/user';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {ClientService} from '../../services/client.service';
import {Client} from '../../classes/client';
import {AuthService} from '../../services/auth.service';
import {RedirectService} from '../../services/redirect.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {Auth} from '../../classes/auth';
import {PermissionsResource} from '../../interfaces/permissions-resource';
import {PermissionService} from '../../services/permission.service';
import {SearchService} from '../../services/search.service';
import {Subscription} from 'rxjs';
import {Municipio} from 'src/app/interfaces/municipio';
import {MdcDialog} from '@angular-mdc/web';
import {DialogComponent} from 'src/app/shared/dialog/dialog.component';
import {PrimeiroacessoComponent} from 'src/app/dashboard/primeiroacesso/primeiroacesso.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit, OnDestroy, AfterViewInit {
  visibility = false;
  userForm: FormGroup;
  user: User = new User();
  municipios: any;
  urlProcessoEletronico: any;

  @ViewChild('passwordInput', {static: false}) passwordInput: ElementRef;
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              private userService: UserService,
              private clientService: ClientService,
              private authService: AuthService,
              private redirectService: RedirectService,
              private permissionService: PermissionService,
              private searchService: SearchService,
              private changeDetectorRef: ChangeDetectorRef,
              private recaptchaV3Service: ReCaptchaV3Service,
              private dialog: MdcDialog) {
  }

  get password(): AbstractControl {
    return this.userForm.get('password');
  }

  ngOnInit(): void {
    this.authService.setCurrentAuth(new Auth()).then();
    this.userService.getCurrentUser().then((user: User) => this.user = user);
    this.userForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
      ])

    });
    this.clientService.getMunicipios().subscribe((response) => {
      this.municipios = response['data'][0].link_processoeletronico;
      this.urlProcessoEletronico = `${this.municipios}auth/callback`;

    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.passwordInput.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
  }

  next(): void {
    this.userForm.markAllAsTouched();

    if (this.userForm.valid) {
      this.user.password = this.userForm.value.password;

      const currentUser: User = {...this.user};
      delete currentUser.password;

      this.userService.setCurrentUser(currentUser).then();
      this.clientService.getCurrentClient().then((client: Client) => {
        this.subscriptions.push(this.recaptchaV3Service.execute('password').subscribe((token) => {
          this.subscriptions.push(this.userService.authorize(this.user, client, token).subscribe((response: { redirect: Location }) => {
            this.subscriptions.push(this.userService.login(this.user, client).subscribe((auth: Auth) => {
              this.authService.setCurrentAuth(auth).then(() => {
                this.subscriptions.push(this.permissionService.byClientAndMeViaRole(
                  client,
                  this.searchService.getHttpParams('name', 'asc', 0)
                ).subscribe((permissionsResource: PermissionsResource) => {
                  this.permissionService.setPermissions(permissionsResource.data)
                    .then(() => {
                      if (this.user.primeiroacesso) {
                        this.userService.insereLog(this.user.email, this.user.id, client.id,
                          'Fez o login, entrou na tela de primeiro acesso').subscribe(() => {
                        });
                        this.dialog.open(DialogComponent, {
                          scrollable: true,
                          escapeToClose: false,
                          clickOutsideToClose: false,
                          data: {
                            info: {
                              user: this.user,
                            },
                            component: PrimeiroacessoComponent,
                            title: 'Primeiro Acesso - Troque sua senha',
                            footerActions: true
                          }
                        });
                      }
                    }).then(() => {
                    this.userService.insereLog(this.user.email, this.user.id, client.id,
                      'Trocou a senha e foi redirecionado para o processo Eletrônico.'
                    ).subscribe(() => {
                    });

                    this.dialog.afterAllClosed.subscribe(() => {
                      window.location = response.redirect;
                    });
                  });
                }));
              });
            }));
          }, error => {
            this.userService.insereLog(this.user.email, this.user.id, client.id, error.error.message).subscribe(resp => {
            });
          }));
        }));
      });
    }
  }

  forgot(): void {
    this.router.navigate(['/auth/forgot-password']);
  }
}
