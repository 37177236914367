import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Client} from '../classes/client';
import {BehaviorSubject, Observable} from 'rxjs';
import {Params} from '@angular/router';
import {Storage} from '@ionic/storage';
import {map} from 'rxjs/operators';
import {ClientsResource} from '../interfaces/clients-resource';
import {ClientResource} from '../interfaces/client-resource';
import {ResourceService} from '../interfaces/resource-service';

@Injectable({
  providedIn: 'root'
})
export class ClientService implements ResourceService {
  private storageKey = 'currentClient';
  clients: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);

  constructor(private http: HttpClient, private storage: Storage) {
  }

  getCurrentClient(): Promise<Client> {
    return this.storage.get(this.storageKey);
  }

  setCurrentClient(client: Client): Promise<any> {
    return this.storage.set(this.storageKey, client);
  }

  nameById(id: number = environment.clientId): Observable<Client> {
    return this.http.get(`${environment.api}/api/clients/${id}/name`)
      .pipe(map((response: { data: Client }) => response.data));
  }

  nameByQueryParam(params: Params): Observable<Client> {
    let id: number = environment.clientId;

    if (params.hasOwnProperty('client_id')) {
      id = params.client_id;
    }

    return this.nameById(id);
  }

  index(params: HttpParams): Observable<ClientsResource> {
    return this.http.get<ClientsResource>(`${environment.api}/api/clients`, {params});
  }

  indexBasic(params: HttpParams): Observable<ClientsResource> {
    return this.http.get<ClientsResource>(`${environment.api}/api/clients/index/basic`, {params});
  }

  destroy(client: Client): Observable<ClientResource> {
    return this.http.delete<ClientResource>(`${environment.api}/api/clients/${client.id}`);
  }

  store(client: Client): Observable<ClientResource> {
    return this.http.post<ClientResource>(`${environment.api}/api/clients`, {...client, image_id: client.imageId});
  }

  update(client: Client, secret: boolean = false): Observable<ClientResource> {
    return this.http.put<ClientResource>(`${environment.api}/api/clients/${client.id}`, {
      secret, ...client,
      image_id: client.imageId
    });
  }

  getMunicipios() {
    return this.http.get(`${environment.api}/api/municipios/estado/lista`);
  }
}
