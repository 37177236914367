import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppSettings} from '../../classes/app-settings';
import {LdapSettings} from '../../classes/ldap-settings';
import {LdapHostSettings} from '../../classes/ldap-host-settings';
import {AppSettingsService} from '../../services/app-settings.service';
import {LdapSettingsService} from '../../services/ldap-settings.service';
import {LdapHostSettingsService} from '../../services/ldap-host-settings.service';
import {MdcDialogRef} from '@angular-mdc/web';
import {SearchService} from '../../services/search.service';
import {LdapHostsSettingsResource} from '../../interfaces/ldap-hosts-settings-resource';
import {DialogFooterActionsComponent} from '../../interfaces/dialog-footer-actions-component';
import {Dialog} from '../../interfaces/dialog';
import {LdapSettingsResource} from '../../interfaces/ldap-settings-resource';
import {AppSettingsResource} from '../../interfaces/app-settings-resource';
import {LdapHostSettingsResource} from '../../interfaces/ldap-host-settings-resource';
import {LoadingService} from '../../services/loading.service';
import {forkJoin, Subscription} from 'rxjs';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy, Dialog, DialogFooterActionsComponent {
  hide = true;
  data: object;
  loading = false;
  appSettingsForm: FormGroup;
  ldapSettingsForm: FormGroup;
  dialogRef: MdcDialogRef<Dialog>;
  ldapHostsSettingsForm: FormGroup;
  ldapHostsSettings: LdapHostSettings[] = [];
  appSettings: AppSettings = new AppSettings();
  ldapSettings: LdapSettings = new LdapSettings();

  protected subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
              private appSettingsService: AppSettingsService,
              private ldapSettingsService: LdapSettingsService,
              private loadingService: LoadingService,
              private ldapHostSettingsService: LdapHostSettingsService,
              private snackBar: MatSnackBar,
              private searchService: SearchService) {
  }

  get appSettingsHost(): AbstractControl {
    return this.appSettingsForm.get('host');
  }

  get ldapSettingsEnabled(): AbstractControl {
    return this.ldapSettingsForm.get('enabled');
  }

  get ldapSettingsUsername(): AbstractControl {
    return this.ldapSettingsForm.get('username');
  }

  get ldapSettingsPassword(): AbstractControl {
    return this.ldapSettingsForm.get('password');
  }

  get ldapSettingsPort(): AbstractControl {
    return this.ldapSettingsForm.get('port');
  }

  get ldapSettingsTimeout(): AbstractControl {
    return this.ldapSettingsForm.get('timeout');
  }

  get ldapSettingsBaseDn(): AbstractControl {
    return this.ldapSettingsForm.get('baseDn');
  }

  get ldapSettingsUseSsl(): AbstractControl {
    return this.ldapSettingsForm.get('useSsl');
  }

  get ldapSettingsUseTls(): AbstractControl {
    return this.ldapSettingsForm.get('useTls');
  }

  get ldapHostSettingsHost(): AbstractControl {
    return this.ldapHostsSettingsForm.get('host');
  }

  ngOnInit() {
    this.index();

    this.subscriptions.push(this.loadingService.observable().subscribe((loading: boolean) => this.loading = loading));

    this.ldapHostsSettingsForm = this.formBuilder.group({
      host: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255)
      ])]
    });

    this.ldapSettingsForm = this.formBuilder.group({
      enabled: [this.ldapSettings.enabled],
      username: [this.ldapSettings.username, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      password: [this.ldapSettings.password, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      port: [this.ldapSettings.port, Validators.compose([
        Validators.required,
        Validators.maxLength(4),
      ])],
      timeout: [this.ldapSettings.timeout, Validators.compose([
        Validators.required,
        Validators.maxLength(4),
      ])],
      baseDn: [this.ldapSettings.baseDn, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      useSsl: [this.ldapSettings.useSsl],
      useTls: [this.ldapSettings.useTls],
    });

    this.appSettingsForm = this.formBuilder.group({
      host: [this.appSettings.host, Validators.compose([
        Validators.required,
        Validators.maxLength(255)
      ])]
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  addHost() {
    if (!this.loading && this.ldapHostsSettingsForm.valid && this.ldapHostSettingsHost.value) {
      const ldapHostSettings = new LdapHostSettings();
      ldapHostSettings.host = this.ldapHostSettingsHost.value;

      this.subscriptions.push(this.ldapHostSettingsService.store(ldapHostSettings)
        .subscribe((response: LdapHostSettingsResource) => {
          this.ldapHostSettingsHost.setValue('');
          this.ldapHostSettingsHost.setErrors(null);
          this.ldapHostsSettings.push(response.data);
        }));
    }
  }

  removeHost(ldapHostSettings: LdapHostSettings) {
    this.subscriptions.push(this.ldapHostSettingsService.destroy(ldapHostSettings).subscribe(() => {
      this.ldapHostsSettings.splice(this.ldapHostsSettings.indexOf(ldapHostSettings), 1);
    }));
  }

  save() {
    this.appSettingsForm.markAllAsTouched();
    this.ldapSettingsForm.markAllAsTouched();

    if (this.appSettingsForm.valid && this.ldapSettingsForm.valid) {
      const appSettings = new AppSettings();
      appSettings.host = this.appSettingsHost.value;

      const ldapSettings = new LdapSettings();
      ldapSettings.enabled = this.ldapSettingsEnabled.value;
      ldapSettings.username = this.ldapSettingsUsername.value;
      ldapSettings.password = this.ldapSettingsPassword.value;
      ldapSettings.port = this.ldapSettingsPort.value;
      ldapSettings.timeout = this.ldapSettingsTimeout.value;
      ldapSettings.baseDn = this.ldapSettingsBaseDn.value;
      ldapSettings.useSsl = this.ldapSettingsUseSsl.value;
      ldapSettings.useTls = this.ldapSettingsUseTls.value;

      this.subscriptions.push(forkJoin(
        this.ldapSettingsService.update(ldapSettings),
        this.appSettingsService.update(appSettings)
      ).subscribe(() => {
        this.snackBar.open('Configurações salvas com sucesso.', 'Fechar', {duration: 5000});
        this.dialogRef.close();
      }));
    }
  }

  resetHost() {
    if (this.ldapHostSettingsHost.value === '') {
      this.ldapHostSettingsHost.setValue('');
      this.ldapHostSettingsHost.setErrors(null);
    }
  }

  private index() {
    this.subscriptions.push(this.appSettingsService.show()
      .subscribe((resource: AppSettingsResource) => this.appSettings = resource.data)
      .add(() => this.appSettingsForm.patchValue(this.appSettings)));

    this.subscriptions.push(this.ldapSettingsService.show()
      .subscribe((resource: LdapSettingsResource) => this.ldapSettings = resource.data)
      .add(() => this.ldapSettingsForm.patchValue(this.ldapSettings)));

    this.subscriptions.push(this.ldapHostSettingsService.index(this.searchService.getHttpParams('host', 'asc', 0))
      .subscribe((resource: LdapHostsSettingsResource) => this.ldapHostsSettings = resource.data));
  }
}
