import {Injectable} from '@angular/core';
import {User} from '../classes/user';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RolesResource} from '../interfaces/roles-resource';
import {Observable} from 'rxjs';
import {Role} from '../classes/role';
import {RoleResource} from '../interfaces/role-resource';
import {ResourceService} from '../interfaces/resource-service';

@Injectable({
  providedIn: 'root'
})
export class RoleService implements ResourceService {

  constructor(private http: HttpClient) {
  }

  index(params: HttpParams): Observable<RolesResource> {
    return this.http.get<RolesResource>(`${environment.api}/api/roles`, {params});
  }

  byUser(user: User): Observable<RolesResource> {
    return this.http.get<RolesResource>(`${environment.api}/api/users/${user.id}/roles`);
  }

  destroy(role: Role): Observable<RoleResource> {
    return this.http.delete<RoleResource>(`${environment.api}/api/roles/${role.id}`);
  }

  update(role: Role): Observable<RoleResource> {
    return this.http.put<RoleResource>(`${environment.api}/api/roles/${role.id}`, role);
  }

  syncAddPermissions(role: Role, permissions: string[]) {
    return this.http.post(`${environment.api}/api/roles/${role.id}/permissions/sync/add`, {permissions});
  }

  syncRemovePermissions(role: Role, permissions: string[]) {
    return this.http.post(`${environment.api}/api/roles/${role.id}/permissions/sync/remove`, {permissions});
  }

  store(role: Role): Observable<RoleResource> {
    return this.http.post<RoleResource>(`${environment.api}/api/roles`, role);
  }
}
