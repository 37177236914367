import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EmailComponent} from './email/email.component';
import {RegisterComponent} from './register/register.component';
import {PasswordComponent} from './password/password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ResendEmailComponent} from './resend-email/resend-email.component';
import { GuestGuard } from '../guards/guest.guard';
import { HasEmailGuard } from '../guards/has-email.guard';
import { AuthGuard } from '../guards/auth.guard.service';

const routes: Routes = [
  {path: '', redirectTo: 'email', pathMatch: 'prefix'},
  {path: 'email', component: EmailComponent},
  {path: 'register', component: RegisterComponent, canActivate: [GuestGuard]},
  {path: 'password', component: PasswordComponent, canActivate: [HasEmailGuard]},
  {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [GuestGuard, HasEmailGuard]},
  {path: 'resend-email', component: ResendEmailComponent, canActivate: [AuthGuard, HasEmailGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
