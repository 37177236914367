import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private router: Router,
              private snackBar: MatSnackBar) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        let message: string = response.status === 0 ? 'Erro desconhecido.' : '';
        

        if (response.status > 0) {
          if (response.error.hasOwnProperty('message')) {
            message += `${response.error.message} `;
          }

          if (response.error.hasOwnProperty('errors')) {
            const key = Object.keys(response.error.errors)[0];
            message += response.error.errors[key][0];
          }
        }


        this.snackBar.open(message, 'Fechar', {duration: 5000});
      

        return throwError(response);
      })
    );
  }
}
