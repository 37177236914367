import {Component, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../classes/client';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../classes/user';
import {ActivatedRoute, Router} from '@angular/router';
import {ClientService} from '../../services/client.service';
import {UserService} from '../../services/user.service';
import {Auth} from '../../classes/auth';
import {AuthService} from '../../services/auth.service';
import {PermissionService} from '../../services/permission.service';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material';
import {MdcDialog, MdcDialogRef} from '@angular-mdc/web';
import {Dialog} from 'src/app/interfaces/dialog';
import {Storage} from '@ionic/storage';

@Component({
  selector: 'app-login',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, OnDestroy {
  client: Client = new Client();
  userForm: FormGroup;
  municipioUser;
  mobile = false;


  dialogRef: MdcDialogRef<Dialog, any>;
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private clientService: ClientService,
              private userService: UserService,
              private authService: AuthService,
              private permissionService: PermissionService,
              private dialog: MdcDialog,
              private snackBar: MatSnackBar,
              private storage: Storage) {
    this.storage.clear();
    localStorage.clear();
    sessionStorage.clear();
  }

  get email(): AbstractControl {
    return this.userForm.get('email');
  }

  ngOnInit(): void {


    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      this.municipioUser = params.municipio;
      this.mobile = params.mobile;

      this.subscriptions.push(this.clientService.nameByQueryParam(params).subscribe((client: Client) => {
        this.client = client;
        this.clientService.setCurrentClient(client);
      }, () => this.router.navigate([`/auth/email${params.client_id}`])));

    }));

    this.userService.setCurrentUser(new User());
    this.authService.setCurrentAuth(new Auth());
    this.permissionService.setPermissions([]);

    this.userForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
      ])
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  next(): void {
    this.userForm.markAllAsTouched();

    if (this.userForm.valid) {
      this.subscriptions.push(
        this.userService.emailExists(
          this.email.value, this.client.id, this.mobile)
          .subscribe((response: { exists: boolean, user: User }) => {
            this.municipioUser = response.user.municipio;
            if (response.exists) {
              this.userService.setCurrentUser(response.user).then(() => {
                this.clientService.getCurrentClient().then((client: Client) => {
                  this.userService.buscaUserNoMunicipio(this.client.municipio, this.email.value).subscribe(response => {
                    if (response.success) {
                      this.router.navigate(['/auth/password']);
                    } else {
                      this.userService.insereLog(
                        this.email.value, null, this.client.id,
                        'Você não tem cadastro nesse município!'
                      ).subscribe(() => {
                      });
                      this.snackBar.open('Você não tem cadastro nesse município!', 'Fechar', {duration: 5000});
                      this.dialogRef.close(true);
                    }
                  });
                });
              });
            } else {
              this.userService.insereLog(this.email.value, null, this.client.id, 'Email ou cpf/cnpj inválido!').subscribe(() => {
              });
              this.snackBar.open('Email ou cpf/cnpj inválido!', 'Fechar', {duration: 5000});
              this.dialogRef.close(true);
            }
          }));
    }
  }

  register() {
    this.router.navigate(['/auth/register']);
  }

}
