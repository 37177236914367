import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from '../../services/loading.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.scss']
})
export class EmptyComponent implements OnInit, OnDestroy {
  @Input() collection: any[] = [];
  @Input() text = '';

  private isLoading = true;
  private isEmpty = false;

  protected subscriptions: Subscription[] = [];

  constructor(private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.isEmpty = this.collection.length === 0;
    this.subscriptions.push(this.loadingService.observable().subscribe((loading: boolean) => this.isLoading = loading));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  loading(): boolean {
    return this.isLoading;
  }

  notLoading(): boolean {
    return !this.isLoading;
  }
}
