import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MdcDialogRef} from '@angular-mdc/web';
import {Client} from '../../../classes/client';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ClientService} from '../../../services/client.service';
import {DialogFooterActionsComponent} from '../../../interfaces/dialog-footer-actions-component';
import {DialogComponent} from '../../../shared/dialog/dialog.component';
import {Dialog} from '../../../interfaces/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EStorageService} from '../../../services/e-storage.service';
import {EStorageResource} from '../../../interfaces/e-storage-resource';
import {Observable, of, Subscription} from 'rxjs';
import {MediaObserver} from '@angular/flex-layout';
import {Role} from '../../../classes/role';
import {RoleService} from '../../../services/role.service';
import {SearchService} from '../../../services/search.service';
import {RolesResource} from '../../../interfaces/roles-resource';
import {map, startWith} from 'rxjs/operators';
import {RoleResource} from '../../../interfaces/role-resource';
import {ClientsResource} from '../../../interfaces/clients-resource';
import { MunicipioResource } from 'src/app/interfaces/municipio-resource';
import { MunicipiosService } from 'src/app/services/municipios.service';

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit, OnDestroy, AfterViewInit, Dialog, DialogFooterActionsComponent {
  data: object;
  image: File;
  roles: Role[] = [];
  clientForm: FormGroup;
  client: Client = new Client();
  dialogRef: MdcDialogRef<DialogComponent>;
  filteredOptions: Observable<Role[]>;
  public municipiosResource: MunicipioResource[] = [];

  protected subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private changeDetectorRef: ChangeDetectorRef,
              private eStorageService: EStorageService,
              private clientService: ClientService,
              private roleService: RoleService,
              private searchService: SearchService,
              public mediaObserver: MediaObserver,
              private municipioService: MunicipiosService,) {
  }

  get name(): AbstractControl {
    return this.clientForm.get('name');
  }

  get redirect(): AbstractControl {
    return this.clientForm.get('redirect');
  }

  get urlaplicacao(): AbstractControl {
    return this.clientForm.get('urlaplicacao');
  }

  get visibilidadeMenu(): AbstractControl {
    return this.clientForm.get('visibilidadeMenu');
  }


  get role(): AbstractControl {
    return this.clientForm.get('role');
  }

  get municipio(): AbstractControl {
    return this.clientForm.get('municipio');
  }

  get linkInterno(): AbstractControl {
    return this.clientForm.get('linkInterno');
  }


  ngOnInit() {

    this.municipioService.getMunicipios().subscribe(response => {
      this.municipiosResource = response.data
    })

    this.clientForm = this.formBuilder.group({
      name: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      redirect: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      urlaplicacao: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      visibilidadeMenu: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      role: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      municipio: ['', Validators.compose([
        Validators.maxLength(255),
      ])],
      linkInterno: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
    });

    this.subscriptions.push(this.roleService.index(this.searchService.getHttpParams(
      'name',
      'asc',
      0
    )).subscribe((response: RolesResource) => {
      this.roles = response.data;

      this.filteredOptions = this.role.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.roles.slice())
        );
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  save(): void {
    this.clientForm.markAllAsTouched();

    if (this.clientForm.valid) {
      let storeResponse = of({
        data: {
          id: null,
          url: null
        }
      });

      if (this.image) {
        const formData = new FormData();
        formData.append('file', this.image);
        formData.append('visibility', 'public');

        storeResponse = this.eStorageService.store(formData);
      }

      let roleStoreResponse;

      if (typeof this.role.value === 'string') {
        const role: Role = new Role();
        role.name = this.role.value;

        roleStoreResponse = this.roleService.store(role);
      } else {
        roleStoreResponse = of({
          data: this.role.value
        });
      }

      this.subscriptions.push(roleStoreResponse.subscribe((roleResource: RoleResource) => {
        this.subscriptions.push(storeResponse.subscribe((response: EStorageResource) => {
          const client: Client = new Client();
          client.name = this.name.value;
          client.redirect = this.redirect.value;
          client.image = response.data.url;
          client.imageId = response.data.id;
          client.role = roleResource.data;
          client.urlAplicacao = this.urlaplicacao.value;
          client.visibilidadeMenu = this.visibilidadeMenu.value;
          client.municipio = this.municipio.value;
          client.linkInterno = this.linkInterno.value;


          this.subscriptions.push(this.clientService.store(client).subscribe(() => {
            if (typeof this.role.value === 'string') {
              this.snackBar.open('Lembre-se de atribuir permissões ao perfil criado.', 'Fechar', {duration: 5000});
            }

            this.dialogRef.close(true);

            this.subscriptions.push(this.clientService.indexBasic(this.searchService.getHttpParams('name', 'asc', 0))
              .subscribe((clientsResource: ClientsResource) => this.clientService.clients.next(clientsResource.data)));
          }));
        }));
      }));

    }
  }

  removeImage() {
    this.image = null;
    this.client.image = null;
  }

  addImage(event: any) {
    const file: File = event.target.files[0];

    if (file.type.match(/image\/*/) == null) {
      this.snackBar.open('É necessário selecionar uma imagem.', 'Fechar', {duration: 5000});
      return;
    }

    this.image = file;

    const reader = new FileReader();
    reader.readAsDataURL(this.image);
    reader.onload = () => this.client.image = reader.result;
  }

  displayFn(role?: Role): string | undefined {
    return role ? role.name : undefined;
  }

  private _filter(name: string): Role[] {
    const filterValue = name.toLowerCase();
    return this.roles.filter(role => role.name.toLowerCase().includes(filterValue));
  }
}
