import {AbstractControl} from '@angular/forms';

export class CustomValidators {
  static passwordConfirmation(control: AbstractControl) {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('password_confirmation').value;
    const passwordConfirmed: boolean = password === confirmPassword;

    control.get('password_confirmation').setErrors(passwordConfirmed ? null : {
      passwordNotConfirmed: true
    });

    if (passwordConfirmed) {
      control.get('password_confirmation').markAsUntouched();
      control.get('password_confirmation').markAsPristine();
    }
  }
}
