import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FlexLayoutModule} from '@angular/flex-layout';
import {
  MdcDialogModule,
  MdcDrawerModule,
  MdcIconModule,
  MdcLinearProgressModule,
  MdcListModule,
  MdcRadioModule,
  MdcTopAppBarModule
} from '@angular-mdc/web';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AccessTokenInterceptor} from './interceptors/access-token.interceptor';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {IonicStorageModule} from '@ionic/storage';
import {UnauthorizedInterceptor} from './interceptors/unauthorized.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {LoadingInterceptor} from './interceptors/loading.interceptor';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {AuthModule} from './auth/auth.module';
import {LoadingService} from './services/loading.service';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {DashboardModule} from './dashboard/dashboard.module';
import {NewButtonClickService} from './services/new-button-click.service';
import {SearchService} from './services/search.service';
import {EStorageService} from './services/e-storage.service';
import {UnauthorizedService} from './services/unauthorized.service';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Paginator} from './classes/paginator';
import {ClientService} from './services/client.service';
import {UserService} from './services/user.service';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    RecaptchaV3Module,
    AuthModule,
    DashboardModule,
    MdcTopAppBarModule,
    MdcIconModule,
    MdcDrawerModule,
    MdcListModule,
    MatSnackBarModule,
    MdcLinearProgressModule,
    MdcRadioModule,
    MdcDialogModule,
  ],
  providers: [
    LoadingService,
    NewButtonClickService,
    SearchService,
    EStorageService,
    UnauthorizedService,
    ClientService,
    UserService,
    {provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey},
    {provide: LOCALE_ID, useValue: 'pt'},
    {provide: MatPaginatorIntl, useClass: Paginator}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
