import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewButtonClickService {
  private subject: Subject<boolean> = new Subject<boolean>();
  private disabled: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  observable(): Observable<boolean> {
    return this.subject.asObservable();
  }

  disabledObservable(): Observable<boolean> {
    return this.disabled.asObservable();
  }

  toggleDisabled(disabled: boolean): void {
    setTimeout(() => this.disabled.next(disabled), 0);
  }

  emit(clicked: boolean): void {
    this.subject.next(clicked);
  }
}
