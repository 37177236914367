import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardComponent} from './dashboard.component';
import {ClientsModule} from './clients/clients.module';
import {
  MdcCardModule,
  MdcCheckboxModule,
  MdcDialogModule,
  MdcDrawerModule,
  MdcElevationModule,
  MdcFabModule,
  MdcFormFieldModule,
  MdcIconButtonModule,
  MdcIconModule,
  MdcImageListModule,
  MdcLinearProgressModule,
  MdcListModule,
  MdcMenuModule,
  MdcTextFieldModule,
  MdcTopAppBarModule,
  MdcTypographyModule
} from '@angular-mdc/web';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import {SettingsComponent} from './settings/settings.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {UsersModule} from './users/users.module';
import {HomeComponent} from './home/home.component';
import {SharedModule} from '../shared/shared.module';
import {MatExpansionModule} from '@angular/material/expansion';
import { PrimeiroacessoComponent } from './primeiroacesso/primeiroacesso.component';

@NgModule({
  declarations: [DashboardComponent, SettingsComponent, HomeComponent, PrimeiroacessoComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ClientsModule,
    MdcTopAppBarModule,
    MdcLinearProgressModule,
    MdcIconModule,
    MdcDrawerModule,
    MdcListModule,
    MdcFabModule,
    MdcFormFieldModule,
    MdcTextFieldModule,
    ExtendedModule,
    FlexModule,
    MdcDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MdcCheckboxModule,
    MdcIconButtonModule,
    MatButtonModule,
    MatIconModule,
    MdcMenuModule,
    UsersModule,
    MdcCardModule,
    MdcTypographyModule,
    MdcImageListModule,
    MdcElevationModule,
    SharedModule,
    MatExpansionModule
  ],
  entryComponents: [
    SettingsComponent,
    PrimeiroacessoComponent
  ]
})
export class DashboardModule {
}
