import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {VerifyEmailComponent} from './auth/verify-email/verify-email.component';
import {ResetPasswordComponent} from './auth/reset-password/reset-password.component';
import {AuthComponent} from './auth/auth.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard.service';

const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'api/email/verify/:user/:hash', component: VerifyEmailComponent, canActivate: [AuthGuard]},
  {path: 'api/password/reset/:token', component: ResetPasswordComponent},
  {path: 'auth', component: AuthComponent, loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {
    path: 'dashboard',
    component: DashboardComponent,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {path: '**', redirectTo: 'dashboard'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
