import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {User} from '../../classes/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RedirectService} from '../../services/redirect.service';
import {timer} from 'rxjs';
import {Client} from '../../classes/client';
import {ClientService} from '../../services/client.service';
import {AuthComponent} from '../auth.component';
import {LoadingService} from '../../services/loading.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent extends AuthComponent implements OnInit {
  client: Client = new Client();
  seconds = 5;
  user: User = new User();
  success = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private redirectService: RedirectService,
    private clientService: ClientService,
    loadingService: LoadingService) {
    super(loadingService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      this.subscriptions.push(this.clientService.nameByQueryParam(params).subscribe((client: Client) => {
        this.client = client;
        this.clientService.setCurrentClient(client);
      }));
    }));

    this.userService.getCurrentUser().then((user: User) => this.user = user);
    this.subscriptions.push(this.userService.verify(this.router.url).subscribe((user: User) => {
      this.snackBar.open('E-mail verificado com sucesso.', 'Fechar', {duration: 5000});
      this.userService.setCurrentUser(user);
      this.success = true;
      this.subscriptions.push(timer(1000, 1000).subscribe(() => {
        this.seconds--;

        if (this.seconds === 0) {
          return;
        }
      }));
    }));
  }

  redirect() {
    this.router.navigate(['/auth/password']);
  }
}
