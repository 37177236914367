import {Injectable} from '@angular/core';
import {AppSettings} from '../classes/app-settings';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {AppSettingsResource} from '../interfaces/app-settings-resource';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  constructor(private http: HttpClient) {
  }

  update(appSettings: AppSettings): Observable<AppSettingsResource> {
    return this.http.put<AppSettingsResource>(`${environment.api}/api/settings/app`, appSettings);
  }

  show(): Observable<AppSettingsResource> {
    return this.http.get<AppSettingsResource>(`${environment.api}/api/settings/app`);
  }
}
