import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogConfirmationComponent} from './dialog-confirmation/dialog-confirmation.component';
import {TreeComponent} from './tree/tree.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {
  MdcDialogModule,
  MdcIconModule,
  MdcLinearProgressModule,
  MdcTextFieldModule,
  MdcTypographyModule
} from '@angular-mdc/web';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {TreeService} from '../services/tree.service';
import {EmptyComponent} from './empty/empty.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DialogComponent} from './dialog/dialog.component';
import {PermissionDirective} from '../directives/permission.directive';

@NgModule({
  declarations: [DialogConfirmationComponent, TreeComponent, EmptyComponent, DialogComponent,
    PermissionDirective],
  imports: [
    CommonModule,
    MatTreeModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MdcDialogModule,
    FlexModule,
    MdcTypographyModule,
    MatProgressSpinnerModule,
    MdcLinearProgressModule,
    FlexLayoutModule,
    MdcTextFieldModule,
    MdcIconModule
  ],
  exports: [TreeComponent, EmptyComponent, PermissionDirective],
  providers: [TreeService],
  entryComponents: [
    DialogComponent,
    DialogConfirmationComponent
  ]
})
export class SharedModule {
}
