import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnauthorizedService {
  private subject = new Subject<void>();

  constructor() {
  }

  observable(): Observable<void> {
    return this.subject.asObservable();
  }

  emit(): void {
    this.subject.next();
  }
}
