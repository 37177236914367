import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {LoadingService} from '../services/loading.service';
import {mergeMap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService,
              private snackBar: MatSnackBar,
              private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.isAuthenticated()).pipe(
      mergeMap((authenticated: boolean) => {
        if (authenticated) {
          request = request.clone({
            setHeaders: {
              Authorization: `${this.authService.auth.tokenType} ${this.authService.auth.accessToken}`
            }
          });
        }

        return next.handle(request);
      })
    );
  }
}
