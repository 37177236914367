import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SettingsComponent} from './settings/settings.component';
import {HomeComponent} from './home/home.component';
import { AuthGuard } from '../guards/auth.guard.service';
import { VerifiedGuard } from '../guards/verified.guard';

const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [AuthGuard, VerifiedGuard]},
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'roles',
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'permissions',
    loadChildren: () => import('./permissions/permissions.module').then(m => m.PermissionsModule),
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'estados',
    loadChildren: () => import('./estados/estados.module').then(m => m.EstadosModule),
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'cities',
    loadChildren: () => import('./municipios/municipios.module').then(m => m.MunicipiosModule),
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'config',
    loadChildren: () => import('./configuracao/configuracao.module').then(m => m.ConfiguracaoModule),
    canActivate: [AuthGuard, VerifiedGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
}
