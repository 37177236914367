import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthRoutingModule} from './auth-routing.module';
import {AuthComponent} from './auth.component';
import {EmailComponent} from './email/email.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {RegisterComponent} from './register/register.component';
import {PasswordComponent} from './password/password.component';
import {ResendEmailComponent} from './resend-email/resend-email.component';
import {VerifyEmailComponent} from './verify-email/verify-email.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MdcCardModule, MdcChipsModule, MdcLinearProgressModule, MdcTypographyModule} from '@angular-mdc/web';
import {MatIconModule} from '@angular/material/icon';
import {IConfig, NgxMaskModule} from 'ngx-mask';
import {MatSelectModule} from '@angular/material/select';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    AuthComponent,
    EmailComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    PasswordComponent,
    ResendEmailComponent,
    VerifyEmailComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule,
    MatButtonModule,
    MdcTypographyModule,
    MdcChipsModule,
    MatIconModule,
    NgxMaskModule.forRoot(options),
    MdcCardModule,
    MdcLinearProgressModule,
    MatSelectModule
  ]
})
export class AuthModule {
}
