import {Component, OnDestroy, OnInit} from '@angular/core';
import {MdcDialogRef} from '@angular-mdc/web';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../../services/user.service';
import {User} from '../../../classes/user';
import {CustomValidators} from '../../../validators/validators';
import {Dialog} from '../../../interfaces/dialog';
import {DialogFooterActionsComponent} from '../../../interfaces/dialog-footer-actions-component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy, Dialog, DialogFooterActionsComponent {
  data: { user: User };
  dialogRef: MdcDialogRef<Dialog, any>;
  userForm: FormGroup;
  visibility = false;
  user: User;

  protected subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private snackBar: MatSnackBar) {
  }

  get name(): AbstractControl {
    return this.userForm.get('name');
  }

  get email(): AbstractControl {
    return this.userForm.get('email');
  }

  get cpf(): AbstractControl {
    return this.userForm.get('cpf');
  }

  get password(): AbstractControl {
    return this.userForm.get('password');
  }

  get password_confirmation(): AbstractControl {
    return this.userForm.get('password_confirmation');
  }

  ngOnInit() {
    this.user = {...this.data.user};

    this.userForm = this.formBuilder.group({
      name: [this.user.name, Validators.compose([
        Validators.required,
        Validators.maxLength(255),
      ])],
      email: [this.user.email, Validators.compose([
        Validators.required,
        Validators.email,
        Validators.maxLength(255),
      ])],
      cpf: [this.user.cpf, Validators.compose([
        Validators.required,
      ])],
      password: ['', Validators.compose([
        Validators.maxLength(255),
        Validators.minLength(8),
      ])],
      password_confirmation: [''],
    }, {
      validator: CustomValidators.passwordConfirmation
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  save(): void {
    this.userForm.markAllAsTouched();

    if (this.userForm.valid) {
      this.user.name = this.name.value;
      this.user.email = this.email.value;
      this.user.cpf = this.cpf.value;

      if (this.password.value) {
        this.user.password = this.password.value;

        Object.assign(this.user, {password_confirmation: this.password_confirmation.value});
      }

      this.subscriptions.push(this.userService.update(this.user).subscribe(() => {
        this.userService.getCurrentUser().then((currentUser: User) => {
          if (currentUser.id === this.user.id) {
            currentUser.name = this.user.name;
            currentUser.email = this.user.email;
            currentUser.cpf = this.user.cpf;
          }

          this.userService.setCurrentUser(currentUser);
        });

        this.snackBar.open('Detalhes do usuário atualizados.', 'Fechar', {duration: 5000});
        this.dialogRef.close(true);
      }));
    }
  }
}
