import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MDC_DIALOG_DATA, MdcDialogRef} from '@angular-mdc/web';
import {DialogHeaderActionsComponent} from '../../interfaces/dialog-header-actions-component';
import {DialogFooterActionsComponent} from '../../interfaces/dialog-footer-actions-component';
import {Dialog} from '../../interfaces/dialog';
import {LoadingService} from '../../services/loading.service';
import {UnauthorizedService} from '../../services/unauthorized.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy, AfterViewInit {
  title: string;
  headerActions = false;
  footerActions = false;
  loading = false;
  editPermission = '';
  destroyPermission = '';

  private componentRef;

  protected subscriptions: Subscription[] = [];

  @ViewChild('content', {read: ViewContainerRef, static: false}) viewContainerRef: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private changeDetectorRef: ChangeDetectorRef,
              private loadingService: LoadingService,
              private unauthorizedService: UnauthorizedService,
              public dialogRef: MdcDialogRef<DialogComponent>,
              @Inject(MDC_DIALOG_DATA) public data: {
                component: Type<Dialog>,
                title: string,
                info?: object,
                headerActions?: boolean,
                footerActions?: boolean,
                editPermission?: string,
                destroyPermission?: string,
              }) {
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.headerActions = this.data.headerActions ? this.data.headerActions : false;
    this.footerActions = this.data.footerActions ? this.data.footerActions : false;
    this.editPermission = this.data.editPermission ? this.data.editPermission : '';
    this.destroyPermission = this.data.destroyPermission ? this.data.destroyPermission : '';
    this.subscriptions.push(this.loadingService.observable().subscribe((loading: boolean) => this.loading = loading));
    this.subscriptions.push(this.unauthorizedService.observable().subscribe(() => this.dialogRef.close()));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.data.component);
    const viewContainerRef = this.viewContainerRef;

    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.dialogRef = this.dialogRef;
    this.componentRef.instance.data = this.data.info ? this.data.info : {};

    this.changeDetectorRef.detectChanges();
  }

  edit() {
    (this.componentRef.instance as DialogHeaderActionsComponent).edit();
  }

  destroy() {
    (this.componentRef.instance as DialogHeaderActionsComponent).destroy();
  }

  save() {
    (this.componentRef.instance as DialogFooterActionsComponent).save();
  }
}
