export class LdapSettings {
  id: number;
  enabled: boolean;
  username: string;
  password: string;
  port: number;
  timeout: number;
  baseDn: string;
  useSsl: boolean;
  useTls: boolean;
  createdAt: string;
  updatedAt: string;
}
