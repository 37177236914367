import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from '../services/loading.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  loading = false;

  protected subscriptions: Subscription[] = [];

  constructor(private loadingService: LoadingService) {
  }

  ngOnInit() {
    this.subscriptions.push(this.loadingService.observable().subscribe(loading => this.loading = loading));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
