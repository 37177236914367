import {Component, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../classes/client';
import {ClientService} from '../../services/client.service';
import {SearchService} from '../../services/search.service';
import {ClientsResource} from '../../interfaces/clients-resource';
import {UserService} from '../../services/user.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {NewButtonClickService} from '../../services/new-button-click.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  clients: Client[];

  protected subscriptions: Subscription[] = [];

  constructor(private clientService: ClientService,
              private searchService: SearchService,
              private userService: UserService,
              private authService: AuthService,
              private recaptchaV3Service: ReCaptchaV3Service,
              private newButtonClickService: NewButtonClickService
  ) {
  }

  ngOnInit() {
    this.newButtonClickService.toggleDisabled(true);

    this.subscriptions.push(this.clientService.indexBasic(this.searchService.getHttpParams(
      'name',
      'asc',
      0
    )).subscribe((response: ClientsResource) => this.clients = response.data));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  goToClient(client: Client) {
    this.subscriptions.push(this.authService.authorize(client).subscribe((response: { redirect: Location }) => {
      window.open(response.redirect.toString(), '_blank');
    }));
  }
}
