import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../../classes/user';
import {UserService} from '../../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ClientService} from '../../services/client.service';
import {Client} from '../../classes/client';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-verify-email',
  templateUrl: './resend-email.component.html',
  styleUrls: ['./resend-email.component.scss']
})
export class ResendEmailComponent implements OnInit, OnDestroy {
  user: User = new User();

  private subscriptions: Subscription[] = [];

  constructor(private userService: UserService,
              private clientService: ClientService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.userService.getCurrentUser().then((user: User) => this.user = user);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  send() {
    this.clientService.getCurrentClient().then((client: Client) => {
      this.subscriptions.push(this.userService.resendEmail(client).subscribe(() => {
        this.snackBar.open('E-mail enviado com sucesso.', 'Fechar', {duration: 5000});
      }));
    });
  }
}
