import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Storage} from '@ionic/storage';
import {Auth} from '../classes/auth';
import {environment} from '../../environments/environment';
import {Client} from '../classes/client';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentAuthObservable: Observable<Auth>;
  private currentAuthSubject: BehaviorSubject<Auth> = new BehaviorSubject<Auth>(new Auth());

  constructor(private http: HttpClient, private storage: Storage) {
    this.storage.get('currentAuth').then(currentAuth => {
      this.currentAuthSubject = new BehaviorSubject<Auth>(currentAuth);
    });

    this.currentAuthObservable = this.currentAuthSubject.asObservable();
  }

  public get auth() {
    return this.currentAuthSubject.value;
  }

  setCurrentAuth(auth: Auth) {
    this.currentAuthSubject.next(auth);
    return this.storage.set('currentAuth', auth);
  }

  public isAuthenticated(): Promise<boolean> {
    return this.storage.get('currentAuth').then((auth: Auth) => !!auth && !!auth.accessToken);
  }

  authorize(client: Client) {
    return this.http.get(`${environment.api}/api/authorize/client`, {
      params: new HttpParams().set('client_id', client.id.toString()).set('response_type', 'code'),
    });
  }

  async logout(api = true) {
    if (api) {
      await this.http.get(`${environment.api}/api/logout`).toPromise();
    }

    await this.storage.remove('currentAuth');
    await this.storage.remove('currentUser');
    await this.storage.remove('permissions');
  }
}
