import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {Client} from '../classes/client';
import {ClientService} from '../services/client.service';
import {UnauthorizedService} from '../services/unauthorized.service';
import {environment} from '../../environments/environment';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
              private router: Router,
              private unauthorizedService: UnauthorizedService,
              private clientService: ClientService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      
      catchError((response: HttpErrorResponse) => {
        if ([401, 403].indexOf(response.status) !== -1) {
          this.unauthorizedService.emit();
          this.clientService.getCurrentClient().then((client: Client) => {
            this.router.navigate(['/dashboard'], {
              queryParams: {
                client_id: client ? client.id : null,
              }
            });
          });
        }
        return throwError(response);
      })
    );
  }
}
