import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {User} from '../classes/user';
import {Client} from '../classes/client';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http: HttpClient) {
  }

  sendResetLinkEmail(user: User, client: Client): Observable<object> {
    return this.http.post(`${environment.api}/api/password/email`, user, {
      params: new HttpParams().set('client_id', client.id.toString())
    });
  }
}
