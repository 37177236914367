import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {LdapHostsSettingsResource} from '../interfaces/ldap-hosts-settings-resource';
import {LdapHostSettings} from '../classes/ldap-host-settings';
import {LdapHostSettingsResource} from '../interfaces/ldap-host-settings-resource';

@Injectable({
  providedIn: 'root'
})
export class LdapHostSettingsService {

  constructor(private http: HttpClient) {
  }

  index(params: HttpParams): Observable<LdapHostsSettingsResource> {
    return this.http.get<LdapHostsSettingsResource>(`${environment.api}/api/settings/ldap/hosts`, {params});
  }

  destroy(ldapHostSettings: LdapHostSettings): Observable<LdapHostSettingsResource> {
    return this.http.delete<LdapHostSettingsResource>(`${environment.api}/api/settings/ldap/hosts/${ldapHostSettings.id}`);
  }

  store(ldapHostSettings: LdapHostSettings): Observable<LdapHostSettingsResource> {
    return this.http.post<LdapHostSettingsResource>(`${environment.api}/api/settings/ldap/hosts`, ldapHostSettings);
  }
}
