import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Municipio } from '../interfaces/municipio';
import { MunicipioResource } from '../interfaces/municipio-resource';

@Injectable({
  providedIn: 'root'
})
export class MunicipiosService {

  constructor(private http: HttpClient) { }

  public getMunicipiosComEstados() {
    return this.http.get<any>(`${environment.api}/api/municipios/estado/lista`);
  }

  public atualizaMunicipio(municipio: Municipio) {
    return this.http.post<any>(`${environment.api}/api/municipios/update`, municipio);
  }

  public persist(municipio: Municipio) {
    return this.http.post<any>(`${environment.api}/api/municipios`, municipio);
  }

  public destroy(municipio: Municipio) {
    const id = municipio.id;
    return this.http.delete<any>(`${environment.api}/api/municipios/${id}`);
  }

  public getMunicipios() {
    return this.http.get<any>(`${environment.api}/api/municipios`);
  }

  public getAll() {
    return this.http.get<MunicipioResource>(`${environment.api}/municipios/lista`);
  }

}
