import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { Municipio } from 'src/app/interfaces/municipio';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  municipios:Municipio[] = [];


  constructor(private router: Router, private clientService: ClientService) {}

  ngOnInit(): void {

  }

  login(): void {
      this.router.navigate(['/auth/email']);
  }

  ngAfterViewInit()
  {
    this.clientService.getMunicipios().subscribe((response) => {

      for (let i = 0; i < response['data'].length; i++) {
        if (response['data'][i].link_processoeletronico == null || response['data'][i].link_processoeletronico == '') {
          continue;
        } else {
          this.municipios.push(response['data'][i]);
        }
      }
    });
  }


  redirectProcessoEletronico(municipio)
  {
      window.location.href=`${municipio.link_processoeletronico}primeiro-acesso`
  }

  
};
